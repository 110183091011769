import React from 'react'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { PageTitle } from '../components/Titles'

const contact = () => {
  return (
    <Layout>
      <Seo title='Contact' />
      <PageTitle>Contact</PageTitle>
      <ContactForm />
    </Layout>
  )
}

export default contact
